import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import MatchWidget from 'ground-web/components/v1/MatchWidget';
import { renderButtons } from '../helpers/matches';
import SeeMore from './see-more';
import ConditionalRenderer from './conditionalRenderer';
import bg from '../images/bg-beira-rio-red.png';

const Banner = styled.figure`
  position: relative;
  background: url(${bg});
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 768px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.white};
  font-family: 'Bebas Neue';
  font-size: 2rem;
  margin: 0;
  margin-bottom: 4rem;
  width: 100%;
  max-width: 1024px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  * {
    width: 80%;
    flex-grow: 1;
    flex-shrink: 0;
    margin: 0.5em;
  }

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    flex-direction: row;
    justify-content: space-around;

    * {
      width: unset;
      flex-grow: 0;
      flex-shrink: 1;
      margin: unset;
    }
  }
`;

export default function NextMatchWidget(props) {
  const { match, hideMore, scrollTo, hideLink } = props;

  let countdownTarget;

  if (match.dateUndefined === false && match.timeUndefined === false) {
    countdownTarget = match.matchDate.valueOf();
  }

  const matchData = {
    finished: false,
    score: null,
    championship: {
      name: match.championship,
    },
    date: match.dateUndefined
      ? 'A definir'
      : match.matchDate.locale('pt').format('dddd, DD/MM'),
    time: match.timeUndefined ? 'A definir' : match.matchDate.format('HH:mm'),
    local: match.stadium,
    homeTeam: {
      shield: {
        source: match.homeImg,
        alt: 'Logo do time da casa',
      },
      name: match.homeTeamName,
    },
    visitorTeam: {
      shield: {
        source: match.visitorImg,
        alt: 'Logo do time visitante',
      },
      name: match.visitorTeamName,
    },
  };

  const labels = {
    kickoff: 'Início',
    countdownStart: 'Tempo para a partida:',
    date: 'Data',
    championship: 'Campeonato',
    share: 'Compartilhar',
  };

  const elemRef = useRef();

  useEffect(() => {
    if (scrollTo && elemRef.current) {
      elemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, []);

  return (
    <Banner ref={elemRef}>
      <Title>
        Próximo Jogo
        <ConditionalRenderer
          condition={!hideMore}
          renderOnSuccess={() => <SeeMore to="/partidas" />}
        />
      </Title>
      <Container>
        <MatchWidget
          match={matchData}
          labels={labels}
          theme={{}}
          countdownTarget={countdownTarget}
        >
          <ActionsContainer>{renderButtons(match, true)}</ActionsContainer>
        </MatchWidget>
      </Container>
    </Banner>
  );
}
