import React from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { Link } from 'gatsby';
import { isAfter } from '.';
import InterShield from '../images/internacional.png';
import ConditionalRenderer from '../components/conditionalRenderer';

export function matchEvents(games) {
  return games.reduce(
    (accumulator, current) => {
      const isFinished = isAfter(current.date, current.time, {
        method: 'add',
        params: [3, 'hours'],
      });
      if (current.finished || isFinished) {
        accumulator.previous.push(current);
        return accumulator;
      }
      if (accumulator.next) {
        accumulator.following.push(current);
      } else {
        accumulator.next = current;
      }
      return accumulator;
    },
    {
      previous: [],
      next: null,
      following: [],
    },
  );
}

export function nextMatch(games) {
  return matchEvents(games).next;
}

export function mergeAllGameData(gamesFromApi, matchesFromCms) {
  return gamesFromApi.map((game) => {
    const date = moment(game.date, 'DD/MM/YYYY');

    if (/^a\s+definir/i.test(game.timeText) === false) {
      const time = moment(game.time);

      if (time.isValid()) {
        date.hour(time.hour()).minute(time.minute());
      }
    }

    let isFinished = moment().isAfter(date.subtract(10, 'minutes'));

    if (/^a\s+definir/i.test(game.dateText)) {
      isFinished = false;
    }

    const data = matchesFromCms.find(
      (match) =>
        parseInt(match.servicoDeJogo.matchId, 10) === parseInt(game.gameId, 10),
    );
    if (data) {
      const mergedData = {
        ...game,
        ...data.servicoDeJogo,
        finished: data.servicoDeJogo.finished || isFinished,
        page_slug: `/partidas/${game.year}-${game.month}-${game.day}-${data.slug}`,
        service: data,
      };
      const { review } = data.servicoDeJogo;
      if (review) {
        return {
          ...mergedData,
          review: {
            dummy: false,
            link: `/noticias/${review.slug}`,
          },
        };
      }
      return {
        ...mergedData,
        review: { dummy: true },
      };
    }
    return {
      ...game,
      finished: isFinished,
      match_slug: '',
      tickets: { dummy: true },
      checkin: { dummy: true },
      review: { dummy: true },
      service: null,
    };
  });
}

function replaceAccentedCharacters(text) {
  let newText = text.replace(/[áãâà]/gi, 'a');
  newText = newText.replace(/[éẽêè]/gi, 'e');
  newText = newText.replace(/[íĩîì]/gi, 'i');
  newText = newText.replace(/[óõôò]/gi, 'o');
  newText = newText.replace(/[úũûù]/gi, 'u');
  return newText;
}

export function shieldValidator(params) {
  const { name, image } = params;
  const nameLowerCased = name.toLowerCase();
  if (nameLowerCased === 'internacional') {
    return InterShield;
  }
  const replacedName = replaceAccentedCharacters(nameLowerCased);
  const splittedName = replacedName.split(' ');
  const replacedImage = image.replace(/_/g, '-');
  const result = splittedName.reduce((accumulator, teamName) => {
    if (replacedImage.includes(teamName) || accumulator) {
      return image;
    }
    return '';
  }, '');
  return result;
}

function parseMatchPage(page) {
  const { servicoDeJogo, slug } = page;
  const { checkin, tickets, finished } = servicoDeJogo;
  const parseObject = (obj) => {
    if (!obj || !obj.startDate || !obj.enabled || finished) {
      return null;
    }
    const startDate = moment(obj.startDate, 'YYYY-MM-DD');

    if (obj.startTime) {
      const startTime = moment(obj.startTime, 'HH:mm');
      startDate.hour(startTime.hour()).minute(startTime.minute());
    }
    if (!obj.endDate) {
      return { startDate, endDate: null, link: obj.link };
    }
    const endDate = moment(obj.endDate, 'YYYY-MM-DD');

    if (obj.endTime) {
      const endTime = moment(obj.endTime, 'HH:mm');
      endDate.hour(endTime.hour()).minute(endTime.minute());
    }
    return { startDate, endDate, link: obj.link };
  };

  return {
    slug,
    ...servicoDeJogo,
    checkin: parseObject(checkin),
    tickets: parseObject(tickets),
  };
}

export function parseMatch(match, page) {
  const matchDate = moment(match.date, 'DD/MM/YYYY');
  const dateUndefined = /^a\s+definir/i.test(match.dateText);
  const timeUndefined =
    /^a\s+definir/i.test(match.timeText) || /^a\s+definir/i.test(match.time);

  if (timeUndefined === false) {
    const time = moment(match.time, 'HH:mm');

    if (time.isValid()) {
      matchDate.hour(time.hour()).minute(time.minute());
    }
  }

  const parsed = {
    ...match,
    matchDate,
    dateUndefined,
    timeUndefined,
    matchPage: '',
    matchReview: '',
    checkinOpened: false,
    checkinLink: '',
    ticketsOpened: false,
    ticketsLink: '',
    isFinished:
      !dateUndefined && !timeUndefined && moment().isSameOrAfter(matchDate),
  };

  if (!page) {
    return parsed;
  }
  const {
    review,
    checkin,
    tickets,
    finished,
    slug,
    showButton,
    matchPageLink,
  } = parseMatchPage(page);
  const matchLink =
    matchPageLink || `/partidas/${matchDate.format('YYYY-MM-DD')}-${slug}`;
  const matchPage = showButton === true ? matchLink : '';
  const matchReview = review ? `/noticias/${review.slug}` : '';
  let checkinOpened = false;
  let checkinLink = '';
  let ticketsOpened = false;
  let ticketsLink = '';

  const isOpened = (obj) => {
    const now = moment();

    if (obj.endDate) {
      return (
        now.isSameOrAfter(obj.startDate) &&
        now.isSameOrBefore(obj.endDate) &&
        now.isBefore(matchDate)
      );
    }
    return now.isSameOrAfter(obj.startDate) && now.isBefore(matchDate);
  };

  if (checkin) {
    checkinOpened = isOpened(checkin);
    checkinLink = checkin.link;
  }

  if (tickets) {
    ticketsOpened = isOpened(tickets);
    ticketsLink = tickets.link;
  }

  const finalParsed = {
    ...parsed,
    matchPage,
    matchReview,
    checkinOpened,
    checkinLink,
    ticketsOpened,
    ticketsLink,
    isFinished: finished || moment().isSameOrAfter(matchDate),
  };

  return finalParsed;
}

export async function fetchCalendar(url) {
  const data = await fetch(`${url}/calendar`);
  const { games } = await data.json();
  return games;
}

export async function getCalendar(url, matchesFromCms) {
  const calendar = await fetchCalendar(url);

  function sortMatches(a, b) {
    if (a.dateUndefined && b.dateUndefined) return 0;
    if (a.dateUndefined) return 1;
    if (b.dateUndefined) return -1;

    if (a.matchDate < b.matchDate) return -1;
    if (a.matchDate > b.matchDate) return 1;

    return 0;
  }

  return calendar
    .map((match) => {
      const matchPage = matchesFromCms.find(
        (page) =>
          parseInt(match.gameId, 10) ===
          parseInt(page.servicoDeJogo.matchId, 10),
      );
      return parseMatch(match, matchPage);
    })
    .sort(sortMatches);
}

export async function fetchTable(url, tableId) {
  const data = await fetch(`${url}/tables/${tableId}`);
  const response = await data.json();

  return response;
}

export async function fetchChampionships(url) {
  const data = await fetch(`${url}/championships`);
  const response = await data.json();

  return response;
}

export function getTeamClassifications(tables, teamId) {
  const classifications = {};
  tables.forEach((table) => {
    if (
      table.name.includes('Libertadores') ||
      table.name.includes('Gaúcho 2021')
    ) {
      const games = table.classification.map(({ games }) => games);
      while (!classifications[table.id]) {
        games.forEach((teamArray) => {
          teamArray.forEach((team) => {
            if (team.idEquipe === Number(teamId))
              classifications[table.id] = team;
          });
        });
      }
    } else {
      classifications[table.id] = table.classification.find(
        ({ idEquipe }) => idEquipe === Number(teamId),
      );
    }
  });

  return classifications;
}

export function getChampionships(calendar) {
  return calendar.reduce(
    (accumulator, match) => {
      const { championship, championshipId } = match;
      const championshipYear = championship.split(' ').slice(-1);
      if (!accumulator.find((item) => item.value === championshipId)) {
        accumulator.push({
          label: championship.replace(championshipYear, ''),
          value: championshipId,
        });
      }
      return accumulator;
    },
    [{ label: 'Todos', value: null }],
  );
}

export function filterByChampionshipId(matches, championshipId) {
  return matches.filter((match) => match.championshipId === championshipId);
}

export function getChampionshipMatches(calendar) {
  const matchesByChampionshipId = calendar.reduce((accumulator, match) => {
    const { championshipId } = match;
    const parsedMatch = parseMatch(match);

    if (!accumulator[championshipId]) {
      accumulator[championshipId] = [parsedMatch];
    } else {
      accumulator[championshipId].push(parsedMatch);
    }

    return accumulator;
  }, {});

  Object.keys(matchesByChampionshipId).forEach((id) => {
    const next = matchesByChampionshipId[id].find(
      ({ isFinished }) => !isFinished,
    );
    if (!next) {
      matchesByChampionshipId[id][
        matchesByChampionshipId[id].length - 1
      ].nextMatch = true;
    } else {
      matchesByChampionshipId[id] = matchesByChampionshipId[id].map((match) => {
        if (match.id === next.id) return { ...match, nextMatch: true };
        return match;
      });
    }
  });

  return matchesByChampionshipId;
}

export function parseCalendar(calendar) {
  return calendar.reduce(
    (accumulator, match) => {
      const isAFeaturedMatch =
        match.matchPage.length > 0 ||
        match.checkinOpened === true ||
        match.ticketsOpened === true;
      const notFinished = !match.isFinished;
      if (match.houseGame === true && notFinished && isAFeaturedMatch) {
        accumulator.featuredMatches.push(match);
      }

      if (match.isFinished === true) {
        accumulator.previous.push(match);

        return accumulator;
      }
      if (accumulator.next === null && match.dateUndefined === false) {
        accumulator.next = match;

        return accumulator;
      }
      accumulator.following.push(match);

      return accumulator;
    },
    {
      previous: [],
      next: null,
      following: [],
      featuredMatches: [],
    },
  );
}

const LinkStyled = css`
  align-items: center;
  font-size: ${({ theme }) => theme.text.calcGenerically(12, 16)};
  font-weight: 700;
  line-height: 1.5em;
  border-radius: 2px;
  padding: 0.3rem 0.5rem;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  color: white;
  background-color: ${({ theme }) => theme.colors.primary_dark};
`;

const ExternalLink = styled.a`
  ${LinkStyled}
`;

const InternalLink = styled(Link)`
  ${LinkStyled}
`;

export function renderButtons(match, showLink = true) {
  if (match.isFinished === true) {
    return (
      <ConditionalRenderer
        condition={match.matchReview.length > 0}
        renderOnSuccess={() => (
          <InternalLink key="review" to={match.matchReview}>
            Veja como foi
          </InternalLink>
        )}
      />
    );
  }
  const buttons = [];

  if (match.checkinOpened === true) {
    buttons.push(
      <ExternalLink key="checkin" href={match.checkinLink} rel="noopener">
        Check-in
      </ExternalLink>,
    );
  }
  if (match.ticketsOpened === true) {
    buttons.push(
      <ExternalLink key="tickets" href={match.ticketsLink} rel="noopener">
        Ingressos
      </ExternalLink>,
    );
  }
  if (match.matchPage.length > 0 && showLink) {
    buttons.push(
      <InternalLink key="info" to={match.matchPage}>
        Saiba mais
      </InternalLink>,
    );
  }
  return buttons;
}
